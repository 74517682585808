import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import TopBar from '@/components/TopBar'
import FootBar from '@/components/FootBar'

import './permission.js'
// import './style/var.less'
import 'vant/lib/index.less'

// 单点登录
import Casdoor from 'casdoor-vue-sdk'
import { config } from '../casSetting.js'

Vue.use(Casdoor, config)


Vue.component('TopBar', TopBar)
Vue.component('FootBar', FootBar)

Vue.config.productionTip = false

import { NavBar, Tabbar, TabbarItem, Image as VanImage, Form, Field, Button, Checkbox, CheckboxGroup, Popup, DropdownMenu, DropdownItem, Calendar, Cell, Col, Row, Empty } from 'vant';

Vue.use(NavBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(VanImage);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Popup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Calendar);
Vue.use(Cell);
Vue.use(Col);
Vue.use(Row);
Vue.use(Empty);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
