<template>
  <van-tabbar
    fixed
    safe-area-inset-bottom
    v-model="title"
    active-color="#00A8CD"
    inactive-color="#ABABAB"
    @change="onChange">
    <van-tabbar-item name="活动" icon="home-o">活动</van-tabbar-item>
    <van-tabbar-item name="赛事" icon="search">赛事</van-tabbar-item>
    <van-tabbar-item name="新闻" icon="friends-o">新闻</van-tabbar-item>
    <van-tabbar-item name="个人中心" icon="setting-o">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'FootBar',
  computed: {
    ...mapGetters([
      'title',
      'backFlag'
    ])
  },
  methods: {
    onChange(index) {
      switch(index) {
        case '活动':
          this.$router.push({ path: '/home' })
          break;
        case '赛事':
          this.$router.push({ path: '/contest' })
          break;
        case '新闻':
          this.$router.push({ path: '/news' })
          break;
        case '个人中心':
          if (this.$store.state.user.token) {
            this.$router.push({ path: '/personal' })
          } else {
            this.$router.push({ path: '/login' })
          }
          break;
      }
    }
  }
}
</script>

