import Cookies from 'js-cookie'

const TokenKey = 'User-Token'
const CasTokenKey = 'Cas-Token'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getCasToken () {
  return Cookies.get(CasTokenKey)
}

export function setCasToken (token) {
  return Cookies.set(CasTokenKey, token)
}

export function removeCasToken () {
  return Cookies.remove(CasTokenKey)
}
