import router from './router'
import { getToken } from '@/utils/auth'
import store from './store'

// 检测名单
const protectList = ['Home', 'Personal', 'News', 'Contest', 'Login']

router.beforeEach((to, from, next) => {
  // // 在检测名单
  if (protectList.indexOf(to.name) !== -1) {
    switch(to.name) {
      case 'Home':
        store.commit('topbar/SET_TITLE', '活动')
        break;
      case 'Personal':
        store.commit('topbar/SET_TITLE', '个人中心')
        break;
      case 'News':
        store.commit('topbar/SET_TITLE', '新闻')
        break;
      case 'Contest':
        store.commit('topbar/SET_TITLE', '赛事')
        break;
      case 'Login':
        store.commit('topbar/SET_TITLE', '登录')
        break;
      default:
        store.commit('topbar/SET_TITLE', '活动')
    }
  }
  // 从单点登录进入
  if (to.query.code && to.query.state) {
    store.dispatch('user/GetPlatformTOken', {
      code: to.query.code,
      state: to.query.state
    }).then(() => {
      router.replace(to.path)
      next()
    }).catch(() => {
      store.dispatch('user/LogOut')
      next()
    })
  }
  if (getToken()) {
    // 存在token
    if (store.getters.user && store.getters.user.userId) {
      next();
    } else {
      store.dispatch('user/GetInfo').then(() => {
        // console.log("userInfo: ", res)
        next()
      }).catch(() => {
        store.dispatch('user/LogOut').then(() => {
          next({ path: '/' })
        })
      })
    }
  } else {
    // 不存在token
    // store.commit('topbar/SET_TITLE', '登录')
    // store.commit('topbar/SET_BACK_FLAG', true)
    // next({ path: '/login' })
    next()
  }
})