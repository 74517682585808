<template>
  <van-nav-bar
    safe-area-inset-top
    :title="title"
    :left-arrow="backFlag"
    @click-left="onClickLeft"
  />
</template>

<script>
export default {
  name: 'TopBar',
  props: {
    title: {
      typeof: String,
      default: '智芽社区'
    },
    backFlag: {
      typeof: Boolean,
      default: true
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>

</style>