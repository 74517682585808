import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/activity/index.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/activity/index.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register/index.vue')
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('../views/personal/index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/news/index.vue')
  },
  {
    path: '/contest',
    name: 'Contest',
    component: () => import('../views/contest/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
