/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams (params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    const part = encodeURIComponent(propName) + '='
    if (value !== null && value !== '' && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (
            value[key] !== null &&
            value[key] !== '' &&
            typeof value[key] !== 'undefined'
          ) {
            const params = propName + '[' + key + ']'
            const subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&'
      }
    }
  }
  return result
}

/* 获取地址栏参数 */
/* eslint-disable */
export function getUrlParams (params) {
  const GET = {}
  const querystr = params.split('?')
  let queryText = ''
  if (querystr.length > 2) {
    for (let i = 0; i < querystr.length; i++) {
      if (i > 1) {
        queryText = querystr[1] += `?${querystr[i]}`
      }
    }
  } else {
    queryText = querystr[1]
  }

  if (queryText) {
    const GETs = queryText.split('&')
    for (let i = 0; i < GETs.length; i++) {
      const tmp_arr = GETs[i].split('=')
      const key = tmp_arr[0]
      if (tmp_arr.length > 2) {
        for (let i = 0; i < tmp_arr.length; i++) {
          if (i > 1) {
            GET[key] = tmp_arr[1] += `=${tmp_arr[i]}`
          }
        }
      } else {
        GET[key] = tmp_arr[1]
      }
    }
  }
  return GET
}

// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

// 向下取整十
export function getFloorUnit (num) {
  let numStr = Math.floor(num)
  if (numStr > 10) {
    return Math.floor(num / 10) * 10 + '+'
  } 
  return numStr
}

// 验证是否为blob格式
export function blobValidate(data) {
  return data.type !== 'application/json'
}
