const state = {
  title: '', // 顶部标题
  backFlag: false, // 是否显示返回按钮
}

const mutations = {
  SET_TITLE: (state, title) => {
    state.title = title
  },
  SET_BACK_FLAG: (state, bool) => {
    state.backFlag = bool
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}