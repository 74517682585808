import { getToken, setToken, removeToken, getCasToken, setCasToken, removeCasToken } from '@/utils/auth'
import { singleLogin, logout, getInfo } from '@/api/user'

const state = {
  token: getToken(),
  cas_token: getCasToken(),
  avatar: '',
  user: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    setToken(token)
    state.token = token
  },
  SET_CAS_TOKEN: (state, token) => {
    state.cas_token = token
  },
  SET_USER: (state, user) => {
    state.user = user
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
    state.user.avatar = avatar
  },
}

const actions = {
  // cas_token换取平台token
  // eslint-disable-next-line no-unused-vars
  GetPlatformTOken ({ dispatch, commit, state }, params) {
    return new Promise((resolve, reject) => {
      singleLogin(params)
        .then(res => {
          commit('SET_CAS_TOKEN', res.casToken)
          commit('SET_TOKEN', res.token)
          setToken(res.token)
          setCasToken(res.casToken)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // 退出系统
  LogOut ({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token, state.cas_token).then(() => {
        commit('SET_CAS_TOKEN', '')
        commit('SET_TOKEN', '')
        removeToken()
        removeCasToken()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 获取用户信息
  // eslint-disable-next-line no-unused-vars
  GetInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo().then(res => {
        const user = res.user
        const avatar = (user.avatar === '' || user.avatar == null) ? require('@/assets/default-avatar.svg') : "/zhiya" + user.avatar
        commit('SET_USER', user)
        commit('SET_AVATAR', avatar)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}